import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '../../../services/API';
import {
  atualizarPerfilSucesso,
  atualizarPerfilFalhou,
  resgatarPremioSucesso,
  resgatarPremioFalhou,
  aceitarRegulamento,
  alterarStatusCartao,
  atualizarSaldoSucesso,
  atualizarSaldoFalhou,
} from './actions';

import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export function* atualizarPerfil({ payload }) {
  try {
    const response = yield call(api.put, '/api/parceiros', payload);

    yield put(atualizarPerfilSucesso(response.data));
    toast.success('Dados atualizado com sucesso', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
  } catch (err) {
    yield put(atualizarPerfilFalhou());
  }
}

export function* resgatarPremio({ payload }) {
  const { codigoPremio, qtde, obs } = payload;

  try {
    const response = yield call(api.post, '/api/resgatar', {
      codigoPremio,
      qtde,
      obs,
    });

    if (response.status === 500) {
      throw new Error(response.data.mensagem);
    }

    yield put(resgatarPremioSucesso(response.data));
    
    toast.success('Resgate realizado com sucesso!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
    const saldoNovo = Number(response.data.saldoNovo);
    toast.success(
      `Seu saldo atual é de ${
        isNaN(saldoNovo) ? 0 : saldoNovo.toLocaleString('pt-BR')
      } pontos`,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      }
    );
    historico.push('/resgates');
  } catch (err) {
    yield put(resgatarPremioFalhou());
    
    const mensagemErro = err.message || err.response?.data?.mensagem || 'Erro ao realizar o resgate. Tente novamente mais tarde.';
    
    toast.error(mensagemErro, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
  }
}

export function* aceitouRegulamento() {
  yield call(api.put, '/api/parceiros', { aceitouRegulamento: 1 });
  put(aceitarRegulamento());
}

export function statusCartao({ payload }) {
  put(alterarStatusCartao(payload));
}

export function* atualizarSaldo({ payload }) {
  // const { cpf } = payload;

  try {
    const response = yield call(api.get, `/api/pontuacoes/saldo/${payload}`);

    let saldo = Number(response.data[0].saldoPontos);

    yield put(atualizarSaldoSucesso(saldo));
  } catch (err) {
    yield put(atualizarSaldoFalhou());
  }
}

export default all([
  takeLatest('@parceiro/ATUALIZAR_PERFIL', atualizarPerfil),
  takeLatest('@parceiro/RESGATAR_PREMIO', resgatarPremio),
  takeLatest('@parceiro/ACEITAR_REGULAMENTO', aceitouRegulamento),
  takeLatest('@parceiro/ALTERAR_STATUS_CARTAO', statusCartao),
  takeLatest('@parceiro/ATUALIZAR_SALDO', atualizarSaldo),
]);
